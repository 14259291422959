import { ORANGE_LOGO_WHITE_TEXT, ARAB_LOGO } from "./assets/images/index";

export class LogoType {
    static LONGANVISION = "longanvision"
    static ARAB = "arab"
}

/**
 * Get logo file source based on user configuration (usually from .env)
 * @param {String} userConfig
 * @returns {String} Logo file source
 */
export function getLogo(userConfig) {
    let logo = "";
    switch (userConfig) {
      case LogoType.LONGANVISION:
        logo = ORANGE_LOGO_WHITE_TEXT;
        break;
      case LogoType.ARAB:
        logo = ARAB_LOGO;
        break;
      default:
        logo = ORANGE_LOGO_WHITE_TEXT;
        break;
    }
  
    if (!logo) {
      throw new Error("Failed to get logo src (empty).");
    }

    return logo;
}

/**
 * configuration for the entire react app
 */
export const config = {
    "STREAM_BASE_URL": process.env.REACT_APP_STREAM_BASE_URL,
    "STREAM_NAME": process.env.REACT_APP_STREAM_NAME,
    "RTMP_PORT": process.env.REACT_APP_RTMP_PORT,
    "WEBRTC_PORT": process.env.REACT_APP_WEBRTC_PORT,
    // LOGO TYPE (enum<int>):
    // 1. longanvision
    // 2. arab
    "LOGO_TYPE": process.env.REACT_APP_LOGO_TYPE,
    "LOGO": getLogo(process.env.REACT_APP_LOGO_TYPE),
};


/** 
 * ===================
 * The App environment
 * ===================
 * Unless explicitly specified REACT_APP_APP_ENV, APP_ENV defaults to development
 * When pushing to production, REACT_APP_APP_ENV='production' npm start
 *                          or REACT_APP_APP_ENV='production' npm build
 **/
export const APP_ENV = process.env.REACT_APP_APP_ENV === 'production' ? 'production' : 'development';

export const LOG_LEVEL = APP_ENV === 'production' ? 'warn' : 'log';