import React from 'react'
import './LogoButton.scss'
import { useNavigate } from "react-router-dom";
import { config } from "../../../config";


const LogoButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/home');
    }

    const logo = config.LOGO;

    return (
        <button className='logo-btn' onClick={handleClick}>
            <img src={logo} alt='longan vision logo' />
        </button>
    )
}

export default LogoButton;