import React, { useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import "./Signin.scss"
import { logger } from '../../logger';
import { config } from '../../config';

/**
 * A component that renders a sign in form.
 * @returns {JSX.Element} The sign in form.
 */
const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const logo = config.LOGO;

  /**
   * A function that signs in the user with the provided email and password.
   * @param {Event} event - The form submission event.
   */
  const signIn = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch(err) {
      logger.log(`Sign in failed: ${err}`);
    }
  };

  return (
    <div className='sign-in-container'>
      <img src={logo} 
           alt='white_logo' 
      />
      <form onSubmit={signIn}>
        <h3>Account</h3>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <h3>Password</h3>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></input>
        <button className='login' type='submit'>
          <span>Log In</span>
        </button>
      </form>
      <div className='signupDiv'>
      </div>
    </div>
  );
};

export default SignIn;
